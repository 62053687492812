import React, { useEffect, useRef } from 'react';
import { GoogleMap, withScriptjs, withGoogleMap, Marker } from 'react-google-maps';
import { SearchBox } from "react-google-maps/lib/components/places/SearchBox";
import mapStyle from '../../../static/assets/data/dealers/styleMap.json';
import pin from '../../../static/assets/images/dealers/distibuidores_cinsa_pin.png';
import pinOver from '../../../static/assets/images/dealers/distibuidores_cinsa_pinOver.png';

function Map({location, ref}) {
    const refSearch = useRef(null)
    return(
        <GoogleMap
            ref={ref}
            defaultZoom={10}
            defaultCenter={{lat: location.data.coordinates.latitude, lng: location.data.coordinates.longitude}}
            defaultOptions={{styles: mapStyle, streetViewControl: false, fullscreenControl: false, mapTypeControl: false }}
            zoom={location ? 17 : 10}
        >
            <Marker icon={pinOver} key={2} position={{lat: location.data.coordinates.latitude, lng: location.data.coordinates.longitude}} />
        </GoogleMap>
    )
}

function DefaultMap({location, locations, ref, handleMarkerClick}) {


    return(
        <GoogleMap
            ref={ref}
            defaultZoom={15}
            defaultCenter={{lat: location.data.coordinates.latitude, lng: location.data.coordinates.longitude}}
            defaultOptions={{styles: mapStyle, streetViewControl: false, fullscreenControl: false, mapTypeControl: false }}
        >
            <Marker icon={pinOver} key={2} position={{lat: location.data.coordinates.latitude, lng: location.data.coordinates.longitude}} />
            {
                locations.map((locationItem, i) => {

                    if (locationItem.id === location.id){
                        return;
                    }
                    return <Marker
                                key={i + "-location"}
                                onClick={()=>handleMarkerClick(locationItem)}
                                icon={pin}
                                position={{lat: locationItem.data.coordinates.latitude, lng: locationItem.data.coordinates.longitude}}
                            />
                })
            }
        </GoogleMap>
    )
}

function MapLg({location, locations, defaultLocation, ref, overStore, isBack, setIsBack, overBranchoffice}) {
    const refMap = useRef(null)
    const refSearch = useRef(null)
    function fitStateBounds(locations){        
        
        if(locations.length!==0) {
            let bounds;
            bounds = new window.google.maps.LatLngBounds();
            locations.map((_location)=>{
                
                if(_location!==null){
                    bounds.extend(new window.google.maps.LatLng(
                        _location.data.coordinates.latitude,
                        _location.data.coordinates.longitude
                    ));
                }
                
            })
           
            refMap.current.fitBounds(bounds)
        }       
        
    }

    useEffect(()=>{
        fitStateBounds(locations)
    },[locations])

    function handleOverStore(locationItem) {
        if(overStore === null) return pin;

        if(overStore.toLowerCase() === locationItem.data.store.toLowerCase() ) {
            return pinOver
        }
        return pin
    }

    useEffect(() => {
        if(!isBack) return

        fitStateBounds(locations)
        setIsBack(false)
    }, [isBack])

    return(
        <GoogleMap
            ref={refMap}
            defaultZoom={5}
            defaultCenter={{lat: defaultLocation.lat, lng: defaultLocation.lng}}
            center= {{                
                lat: (locations.length===0) ? defaultLocation.latitude : (location!==null) ? location.data.coordinates.latitude : null, 
                lng: (locations.length===0) ? defaultLocation.lngitude : (location!==null) ? location.data.coordinates.longitude : null
            }}

            zoom={location ? 15 : defaultLocation.zoom}
            defaultOptions={{styles: mapStyle, streetViewControl: false, fullscreenControl: false, mapTypeControl: false, zoomControlOptions: { position: 6 }}}
        >
            {
                location ?

                    locations.map((locationItem, i) => {
                        // console.log('location', location)
                        // console.log('locationItem', locationItem)
                        if(location.uid !== locationItem.uid){

                            return <Marker
                                        key={i + "-location"}
                                        icon={pin}
                                        position={{lat: locationItem.data.coordinates.latitude, lng: locationItem.data.coordinates.longitude}}
                                    />
                        } else{
                            // console.log('--------------no coloco el pin--------------')
                            return null
                        }
                    })
                :
                    locations.map((locationItem, i) => {
                        return <Marker
                                    key={i + "-location"}
                                    icon={handleOverStore(locationItem)}
                                    position={{lat: locationItem.data.coordinates.latitude, lng: locationItem.data.coordinates.longitude}}
                                />
                    })
            }
            {
                location &&
                    <Marker
                        icon={pinOver}
                        position={{lat: location.data.coordinates.latitude, lng: location.data.coordinates.longitude}}
                    />
            }
            <SearchBox
                ref={refSearch}
                // bounds={}
                // controlPosition={google.maps.ControlPosition.TOP_LEFT}
                onPlacesChanged={()=>{}}
                >
                <input
                    type="text"
                    id="buscador"
                    placeholder="Customized your placeholder"
                    style={{
                    boxSizing: `border-box`,
                    border: `1px solid transparent`,
                    width: `240px`,
                    height: `32px`,
                    marginTop: `27px`,
                    padding: `0 12px`,
                    borderRadius: `3px`,
                    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                    fontSize: `14px`,
                    outline: `none`,
                    textOverflow: `ellipses`,
                    }}
                />
                </SearchBox>
        </GoogleMap>
    )
}

export const WrappedMapDefault = withScriptjs(withGoogleMap(DefaultMap));
export const WrappedMap = withScriptjs(withGoogleMap(Map));
export const WrappedMapLg = withScriptjs(withGoogleMap(MapLg));
