import React from "react";
import SvgArrowReturn from '../../components/svg/commons/arrow_return'
import PhoneContact from '../../components/svg/commons/phone_contact'
import SvgMap from '../../components/svg/dealers/maps';
import SvgSite from '../../components/svg/dealers/site';

const BranchOfficesList = ({branchoffices=[], handleBranchoffice, handleBackMain}) => {
    function handleClick (e, branchoffice) {
        const elActive = document.querySelector(".c-dealers-branchoffices__card.isActive");
        if(elActive) {
            elActive.classList.remove("isActive");
        }
        e.target.parentElement.classList.add("isActive");
        handleBranchoffice(branchoffice)
    }

    function Card(branchoffice) {
        return(
            <div className="c-dealers-branchoffices__card" key={branchoffice.uid}>
                <h3 onClick={e => handleClick(e, branchoffice)}>{branchoffice.data.branchoffice}</h3>
                <p className="c-dealers-branchoffices__card--address" onClick={e => handleClick(e, branchoffice)}>{branchoffice.data.address}</p>
                <p className="c-dealers-branchoffices__card--phone">{branchoffice.data.phone}</p>
                <div className="card__links">
                    <Link _class="card__links--map" text="cómo llegar" url={`http://maps.google.com/maps?&z=15&mrt=yp&t=k&q=${branchoffice.data.coordinates.latitude},${branchoffice.data.coordinates.longitude}`}>
                        <SvgMap />
                    </Link>
                    <Link _class="card__links--site" text="Ver sitio" url={branchoffice.data.website}>
                        <SvgSite />
                    </Link>
                    <Link _class="card__links--phone" text="Llamar" url={`tel:${branchoffice.data.phone}`}>
                        <PhoneContact />
                    </Link>
                </div>
            </div>
        )
    }

    function Link (props) {
        const { text, url, _class='', children } = props;
        return (
            <a className={_class} href={url}>
                {children}
                <span>{text}</span>
            </a>
        )
    }

    return (
        <div className="c-dealers-branchoffices">
            <div className="c-dealers-branchoffices__container">
                {
                    branchoffices.length > 0 ?
                        <div className="c-dealers-branchoffices__title">
                            <div className="c-dealers-branchoffices__back" onClick={() => handleBackMain()}>
                                <SvgArrowReturn />
                                <span>Regresar</span>
                            </div>
                            <h2>{branchoffices[0].data.store}</h2>
                        </div>
                        : null
                }
                {
                    branchoffices.map(branchoffice => Card(branchoffice))
                }
            </div>
        </div>
    )
}

export default BranchOfficesList;