import React from "react"

const PhoneContact = props => (
    <svg viewBox="0 0 192 192" {...props}>
        <g data-name="Layer 2">
            <path
            d="M51 192a49 49 0 01-34-14L3 164l-1-1a9 9 0 011-12l42-40a9 9 0 0113 0l14 14 53-53-14-15a9 9 0 010-12l40-42a9 9 0 015-3 9 9 0 018 3l14 14a49 49 0 010 69l-92 92a49 49 0 01-35 14zm-38-35l12 12a37 37 0 0052 0l92-92a37 37 0 000-52l-12-12-36 38 16 16a6 6 0 011 9l-62 62a6 6 0 01-8 0l-17-17zm36-38zM155 11z"
            data-name="Layer 1"
            />
        </g>
    </svg>
)

export default PhoneContact