import React, { useState, useEffect, useRef } from 'react';

import { useCurrenDevice, DEVICES }  from '../../hooks/useCurrentDevice'
import { states } from '../../../static/assets/data/dealers/api-states';
import {WrappedStores} from './Stores';
// import  Stores from './Stores';
import { WrappedMap, WrappedMapLg, WrappedMapDefault } from '../../components/dealers/dealerMap';
import BranchOfficesList from './branchOfficesList';
import { usePrismLocations } from '../../hooks/use_prims_locations';
import Loader from '../../components/Loader/Loader'
import { fetchGeopoint } from '../../hooks/use_prims_locations_geopoint'
import { MapInfo } from './mapInfo';
import { MapMobile } from './mapMobile';
import SvgSeemapCurveTablet from '../../components/svg/dealers/seemap_curve_tablet'
import SvgSeemapCurveDesktop from '../../components/svg/dealers/seemap_curve_desktop'

const API = 'https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyAH-wS-2_hWwM06yxIZ1oj3XunrhqN_iSM';

const Dealers = () => {
    const [
        locations,
        setLocations,
        stores,
        currentState,
        setCurrentState,
        currentStore,
        branchOffices,
        currentBranchOffice,
        handleSateId,
        handleState,
        handleStore,
        handleBranchoffice,
        handleBranchoffices,
        loading,
        setLoading
        ] = usePrismLocations();

    const [device] = useCurrenDevice();
    const [currentPosition, setCurrentPosition] = useState({latitude: 23.6345005, lngitude: -102.5527878, zoom:5.5}); // default Mexico location
    const [overStore, setOverStore] = useState(null)
    const [overBranchoffice, setOverBranchoffice] = useState(null)
    const [isBack, setIsBack] = useState(false)
    const [inputSearch, setInputSearch] = useState(null)
    const [defaultStore, setDefaultStore] =useState(stores[0])
    const [defaultMapActive, setDefaultMapActive] = useState(false)

    const wrapper = useRef(null);
    const container = useRef(null);

    // set default state and set geolocation
    useEffect( ()  =>  {
        if(device === DEVICES.mobile) {
            handleBranchoffice(null);
            handleState(states[0])
            
        } 

        document.querySelector('footer').style.display = "none";
        document.querySelector('html').classList.add('html-dealers');
        return () => {
            document.querySelector('html').classList.remove('html-dealers');
            document.querySelector('footer').style.display = "block";
        };
    },[])

    useEffect(() => {
        if(!currentStore) return;
        const branchoffices = stores.filter(item => item.data.store.toLowerCase() === currentStore.toLowerCase())
        handleBranchoffices(branchoffices)
        wrapper.current.style.left = '-100%';
    }, [currentStore])
    useEffect(()=>{
        console.log('currentBranchOffice',currentBranchOffice);
        if(currentBranchOffice!==null)
        {
            setCurrentPosition({latitude: currentBranchOffice.data.coordinates.latitude, lngitude: currentBranchOffice.data.coordinates.longitude, zoom: 5.5})    
        }
        
        
    },[currentBranchOffice])
    const filterStates = (inputSearch) =>{
        let outputState = []
        inputSearch.address_components.map((item)=>{
            item.types.map((node)=>{
                if(node === 'administrative_area_level_1'){
                    states.map((state)=>{                        
                        if(item.short_name.toLowerCase().search(state.data.title.shortName.toLowerCase()) !== -1){
                            outputState = state
                        }
                    })
                }
            })  
        })
        return outputState
    }

    useEffect(() => {
        if(inputSearch === null) return
        if(!inputSearch.hasOwnProperty('address_components'))return
        const lat = inputSearch.geometry.location.lat()
        const lng = inputSearch.geometry.location.lng()
        
        setLoading(true)
        const response = fetchGeopoint(lat, lng)
        response.
            then(data => {
                const filteredState = filterStates(inputSearch)
                // setCurrentPosition({latitude: lat, lngitude: lng, zoom: 5.5});
                setLocations(data.results)
                setCurrentState(filteredState)
            }).
            catch(error => console.log(error))
        
    }, [inputSearch])


    function handleBackMain() {
        wrapper.current.style.left = '0';
        handleStore(null)
        handleBranchoffice(null)
        setIsBack(true)
    }

    function handleOverStore (store)  {
        setOverStore(store)
    }
    function handleOverBnachoffice (branchoffice)  {
        setOverBranchoffice(branchoffice)
    }

    function getCurrentPosition(options = {}) {
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject, options);
        });
    }
    
    async function fetchNearestLocations() {
        try {
            const {coords} = await getCurrentPosition({enableHighAccuracy: true});
            const {latitude, longitude} = coords;
            // const latitude = 25.4383;     // Saltillo
            // const longitude = -100.9737;  // Saltillo
            const data = await fetchGeopoint(latitude, longitude)
            const filteredState = states.filter(state =>
                state.uid == data.results[0].data.address_components[0].address_state_id
            );                
            // setCurrentPosition({latitude: latitude, lngitude: longitude, zoom: 5.5});
            setLocations(data.results)
            setCurrentState(filteredState[0]);

        } catch (error) {
            console.error(error)
        }
    }
    function loadGps() {
        setLoading(true);
        fetchNearestLocations();
    }

    function handleDefaultMapActive (isActive)  {
        setDefaultMapActive(isActive)
    }

    useEffect(() => {
        setDefaultStore(stores[0])
    },[stores])

    useEffect(() => {
        console.log('defaultStore', defaultStore)
    },[defaultStore])

    return (
        <section className="c-dealers">
            <div ref={container} className="c-dealers__container">
                {/* { device === DEVICES.tablet && <SvgSeemapCurveTablet/> }
                { device === DEVICES.desktop && <SvgSeemapCurveDesktop/> } */}
                <div ref={wrapper} className="c-dealers__container--wrapper">
                    <WrappedStores
                        handleDefaultMapActive={handleDefaultMapActive}
                        default_item={currentState}
                        handleState={handleSateId}
                        stores={stores}
                        handleStore={handleStore}
                        containerRef={container}
                        loadingElement={<div></div>}
                        googleMapURL='https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyAH-wS-2_hWwM06yxIZ1oj3XunrhqN_iSM'
                        handleOver={handleOverStore}
                        loading={loading}
                        setSearchValue={setInputSearch}
                        handleGps={loadGps}
                        />

                    <BranchOfficesList
                        branchoffices={branchOffices}
                        handleBranchoffice={handleBranchoffice}
                        handleBackMain={handleBackMain}
                        handleOver={handleOverBnachoffice}
                        />
                </div>
            {
                loading && <Loader customClass='main-loader' />
            }
            </div>
            {
                currentBranchOffice && device === DEVICES.mobile &&
                    <MapMobile handleBranchoffice={handleBranchoffice} >
                        <WrappedMap
                            location={currentBranchOffice}
                            googleMapURL={API}
                            loadingElement={<div style={{ height: `100%` }} />}
                            containerElement={<div style={{ position:`relative`, height: `calc(100% - 175px)` }} />}
                            mapElement={<div style={{ height: `100%` }} />}
                        />
                        <MapInfo
                            branchoffice={currentBranchOffice.data.branchoffice}
                            address={currentBranchOffice.data.address}
                            longitude={currentBranchOffice.data.coordinates.longitude}
                            latitude={currentBranchOffice.data.coordinates.latitude}
                            phone={currentBranchOffice.data.phone}
                        />
                    </MapMobile>
            }
            {/* Only Mobile */}
            {
                defaultMapActive && device === DEVICES.mobile &&
                    <MapMobile handleBranchoffice={handleBranchoffice} handleClickClose={setDefaultMapActive} >
                        {/* Pasar el default store y stores */}
                        <WrappedMapDefault
                            location={defaultStore}
                            locations={stores}
                            handleMarkerClick={setDefaultStore}
                            googleMapURL={API}
                            loadingElement={<div style={{ height: `100%` }} />}
                            containerElement={<div style={{ position:`relative`, height: `calc(100% - 175px)` }} />}
                            mapElement={<div style={{ height: `100%` }} />}
                        />
                        <MapInfo
                            branchoffice={defaultStore.data.branchoffice}
                            address={defaultStore.data.address}
                            longitude={defaultStore.data.coordinates.longitude}
                            latitude={defaultStore.data.coordinates.latitude}
                            phone={defaultStore.data.phone}
                        />
                    </MapMobile>
            }
            {
                device !== DEVICES.mobile &&
                    <div className="c-dealers__map">
                        <div className="c-dealers__map__container">
                            <WrappedMapLg
                                location={currentBranchOffice}
                                locations={locations}
                                defaultLocation={currentPosition}
                                googleMapURL={API}
                                loadingElement={<div style={{ height: `100%` }} />}
                                containerElement={<div style={{ height: `100%` }} />}
                                mapElement={<div style={{ height: `100%` }} />}
                                overStore={overStore}
                                overBranchoffice={overBranchoffice}
                                isBack={isBack}
                                setIsBack={setIsBack}
                            />
                        </div>
                    </div>
            }
        </section>
    )
}

export default Dealers;
