import React from 'react'
import CloseIcon from '../../components/svg/commons/close_icon';
import SvgSeemapCurve from '../../components/svg/dealers/seemap_curve'

export const MapMobile = ({children, handleBranchoffice, handleClickClose = () => {}}) => {

    return(
        <div className="c-dealers__map">
            <div className="c-dealers__map__container">
                <div className="c-dealers__map--close" onClick={()=>handleClickClose(false)}>
                    <CloseIcon onClick={() => handleBranchoffice(null)} />
                </div>
                <SvgSeemapCurve/>
                { children }
            </div>
        </div>
    )
}