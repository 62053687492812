import  { useEffect } from "react";

const useOutsideClick = (ref, callback) => {
  /**
   * callback if clicked on outside of element
   */
  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
        // console.log('click fuera del dropdown')
      callback(false)
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
}

export default useOutsideClick;