import * as React from "react"

function GpsIcon(props) {
  return (
    <svg viewBox="0 0 17 17" {...props}>
      <path d="M8.5 5.41a3.09 3.09 0 103.09 3.09A3.1 3.1 0 008.5 5.41zm6.88 2.32a6.9 6.9 0 00-6.1-6.1V0H7.73v1.62a6.76 6.76 0 00-6.1 6.1H0v1.55h1.62a6.9 6.9 0 006.1 6.1V17h1.55v-1.62a6.9 6.9 0 006.1-6.1H17V7.73zM8.5 13.91a5.41 5.41 0 115.41-5.41 5.38 5.38 0 01-5.41 5.41z" />
    </svg>
  )
}

export default GpsIcon
