import React from 'react'
import SvgMap from '../../components/svg/dealers/maps'
import PhoneContact from '../../components/svg/commons/phone_contact'

export const MapInfo = ({branchoffice, address, latitude, longitude, phone}) => {

    function LinkWtIcon (props) {
        const { text, url, _class='',target='', children } = props;
        return (
            <a className={_class} href={url} target={target}>
                {children}
                <span>{text}</span>
            </a>
        )
    }

    return(
        <div className="c-dealers__map--info">
            <div className="c-dealers__map--info_title">{branchoffice}</div>
            <div className="c-dealers__map--info_address">{address}</div>
            <div className="c-dealers__map--info_buttons">
                <LinkWtIcon _class="info-link-map" target="_blank" text="Cómo llegar" url={`http://maps.google.com/maps?&z=15&mrt=yp&t=k&q=${latitude},${longitude}`}>
                    <SvgMap />
                </LinkWtIcon>
                <LinkWtIcon _class="info-link-phone" text="Llamar" url={`tel:${phone}`}>
                    <PhoneContact />
                </LinkWtIcon>
            </div>
        </div>
    )
}