import React from "react"

const SvgSeemapCurve = props => (
  <svg viewBox="0 0 360 10" {...props}>
    <g data-name="Layer 2">
      <path d="M180 10C78 9 0 0 0 0v10h360V0s-78 9-180 10z" />
    </g>
  </svg>
)

export default SvgSeemapCurve
