import React from "react";

import Layout from "../components/layout";
import Dealers from '../content/dealers/dealers'
import "../styles/pages/dealers/_dealers.scss";

import Head from '../components/head'
import GetBodyItem from '../components/_helpers/_get_body_item'
import {graphql, useStaticQuery} from 'gatsby'

const PageDealers = () => {
    const query = useStaticQuery(graphql`
	{
		allPrismicDealerspage{
            edges{
                node{
                    ... on Node{
                        __typename
                        ... on PrismicDealerspage{
                            data{
                                title{
                                    text
                                }
                                body{
                                    __typename
                                    ... on PrismicDealerspageBodyMetas{
                                        primary{
                                            title{
                                            text
                                            }
                                            description{
                                            text
                                            }
                                            image_share{
                                            url
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
		}
    }
    `)

    let metas= {
        title:'Calorex Garantía',
        description: 'Calorex Garantía d',
        image:''
    }

    if(query)//esto no funciona
    {
		let _item = query.allPrismicDealerspage.edges[0];
		let metas_from_prismic = new GetBodyItem().get_item(_item.node,"PrismicDealerspageBodyMetas");

        metas.title = metas_from_prismic.primary.title.text
        metas.description = metas_from_prismic.primary.description.text
        metas.image= metas_from_prismic.primary.image_share.url
	}
    return (
        <Layout isBlue={false} >
            <Dealers />
        </Layout>
    )
}

export default PageDealers