import React from 'react'
import styled from 'styled-components'

import Loading from '../loading/Loading'

export const LoaderCotainer = styled.div`
    font-family: 'galano_grotesque', sans-serif;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 50;
    position: relative;
    span {
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 1px;
    }
    @media screen and (min-width: 768px){
      width: 330px;
      margin-left: 92px;
    }
    @media screen and (min-width:1024px){
      width: 470px;
      margin-left: 30px;
    }
`

const Loader = ({ customClass }) => {
  return (
    <LoaderCotainer className={customClass}>
      <Loading />
      <span>Cargando</span>
    </LoaderCotainer>
  )
}

export default Loader
