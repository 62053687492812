export const states = [
    { "uid": "AGS", state_id: "1", data:{ title: {"text": "Aguascalientes", "shortName": "Ags."}}},
    { "uid": "BC", state_id: "2", data:{ title: {"text": "Baja California", "shortName": "B.C."}}},
    { "uid": "BCS", state_id: "3", data:{ title: {"text": "Baja California Sur", "shortName": "B.C.S."}}},
    { "uid": "CHI", state_id: "8", data:{ title: {"text": "Chihuahua", "shortName": "Chih."}}},
    { "uid": "CHS", state_id: "7", data:{ title: {"text": "Chiapas", "shortName": "Chis."}}},
    { "uid": "CMP", state_id: "4", data:{ title: {"text": "Campeche", "shortName": "Camp."}}},
    { "uid": "CMX", state_id: "9", data:{ title: {"text": "Ciudad de México", "shortName": "CDMX"}}},
    { "uid": "COA", state_id: "5", data:{ title: {"text": "Coahuila", "shortName": "Coah."}}},
    { "uid": "COL", state_id: "6", data:{ title: {"text": "Colima", "shortName": "Col."}}},
    { "uid": "DGO", state_id: "10", data:{ title: {"text": "Durango", "shortName": "Dgo."}}},
    { "uid": "GRO", state_id: "12", data:{ title: {"text": "Guerrero", "shortName": "Gro."}}},
    { "uid": "GTO", state_id: "11", data:{ title: {"text": "Guanajuato", "shortName": "Gto."}}},
    { "uid": "HGO", state_id: "13", data:{ title: {"text": "Hidalgo", "shortName": "Hgo."}}},
    { "uid": "JAL", state_id: "14", data:{ title: {"text": "Jalisco", "shortName": "Jal."}}},
    { "uid": "MCH", state_id: "16", data:{ title: {"text": "Michoacán", "shortName": "Mich."}}},
    { "uid": "MEX", state_id: "15", data:{ title: {"text": "Estado de México", "shortName": "Méx."}}},
    { "uid": "MOR", state_id: "17", data:{ title: {"text": "Morelos", "shortName": "Mor."}}},
    { "uid": "NAY", state_id: "18", data:{ title: {"text": "Nayarit", "shortName": "Nay."}}},
    { "uid": "NL",  state_id: "19", data:{ title: {"text": "Nuevo León", "shortName": "N.L."}}},
    { "uid": "OAX", state_id: "20", data:{ title: {"text": "Oaxaca", "shortName": "Oax."}}},
    { "uid": "PUE", state_id: "21", data:{ title: {"text": "Puebla", "shortName": "Pue."}}},
    { "uid": "QR",  state_id: "23", data:{ title: {"text": "Quintana Roo", "shortName": "Q.R."}}},
    { "uid": "QRO", state_id: "22", data:{ title: {"text": "Querétaro", "shortName": "Qro."}}},
    { "uid": "SIN", state_id: "25", data:{ title: {"text": "Sinaloa", "shortName": "Sin."}}},
    { "uid": "SLP", state_id: "24", data:{ title: {"text": "San Luis Potosí", "shortName": "S.L.P."}}},
    { "uid": "SON", state_id: "26", data:{ title: {"text": "Sonora", "shortName": "Son."}}},
    { "uid": "TAB", state_id: "27", data:{ title: {"text": "Tabasco", "shortName": "Tab."}}},
    { "uid": "TLX", state_id: "29", data:{ title: {"text": "Tlaxcala", "shortName": "Tlax."}}},
    { "uid": "TMS", state_id: "28", data:{ title: {"text": "Tamaulipas", "shortName": "Tamps."}}},
    { "uid": "VER", state_id: "30", data:{ title: {"text": "Veracruz", "shortName": "Ver."}}},
    { "uid": "YUC", state_id: "31", data:{ title: {"text": "Yucatán", "shortName": "Yuc."}}},
    { "uid": "ZAC", state_id: "32", data:{ title: {"text": "Zacatecas", "shortName": "Zac."}}}
]