import React from 'react'

const ArrowHead = props => (
  <svg viewBox="0 0 12.6 21" {...props}>
    <path
      d="M1.5 21c-.4 0-.8-.1-1.1-.4-.6-.6-.6-1.5 0-2.1l7.9-7.9-7.9-8C-.1 2-.1 1 .4.4 1-.1 2-.1 2.6.4l10.1 10.1L2.6 20.6c-.3.3-.7.4-1.1.4z"
      fill={props.fill ? `${props.fill}` : `url(#${props.section}_arrow_head)`}
    />
    {props.color_start===undefined ?  
    <linearGradient
      id={`${props.section}_arrow_head`}
      gradientUnits="userSpaceOnUse"
      x1={6.311}
      y1={21}
      x2={6.311}
      y2={0}
    >
      <stop offset={0} stopColor="#f73855" />
      <stop offset={1} stopColor="#ff6262" />       
    </linearGradient>
    :
    <linearGradient
      id={`${props.section}_arrow_head`}
      gradientUnits="userSpaceOnUse"
      x1={6.311}
      y1={21}
      x2={6.311}
      y2={0}
    >
      <stop offset={0} stopColor={props.color_start} />
      <stop offset={1} stopColor={props.color_end} />       
    </linearGradient>
  }
  </svg>
)

export default ArrowHead