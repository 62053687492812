import React from 'react';

const SvgMap = props => (
	<svg viewBox="0 0 230.2 230.2" {...props}>
		<g data-name="Layer 2">
			<path
			d="M214 1L7 86a12 12 0 000 22l78 37 38 78a12 12 0 0010 7h1a12 12 0 0011-7l84-206a12 12 0 00-15-16zm-81 188l-28-58a12 12 0 00-6-6L42 98l154-64z"
			data-name="Layer 1"
			/>
		</g>
	</svg>
)

export default SvgMap;