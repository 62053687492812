import React, { useEffect, useState, useRef } from 'react';

import Dropdown from '../../components/_comparator/dropdown';
import { states } from '../../../static/assets/data/dealers/api-states';
import { withScriptjs } from 'react-google-maps';
import Search from '../../components/svg/commons/search';
import { useCurrenDevice, DEVICES } from '../../hooks/useCurrentDevice'
import SvgLocation from '../../components/svg/dealers/location'
import { SeeMap } from './seeMap';
import { GpsButton } from '../../components/buttons/_gps_button'

function Stores  ({handleDefaultMapActive, default_item, handleState, stores, handleStore, handleOver, loading, setSearchValue, handleGps }) {
    const [listStores, setListStores] = useState([]);
    const inputSearch= useRef(null);
    const activateGpsButton= useRef(null);
    const [device] = useCurrenDevice();

    function capitalizeFLetter(str) {
        str = str.toLowerCase()
        str = str.charAt(0).toUpperCase() + str.slice(1);
        return str
      }

    useEffect(() => {
        if(!stores) return;
        let listStores = []
        stores.map(item => {
            listStores = [...listStores, capitalizeFLetter(item.data.store)];
        })
        const unique = listStores.filter((v, i, a) => a.indexOf(v) === i); // filtrar unicos
        setListStores(unique);
    }, [stores])

    useEffect(() => {
        let autocomplete = new window.google.maps.places.Autocomplete(inputSearch.current, {
            types: ['(regions)'],
            componentRestrictions: { country: 'mx' }
        });

        autocomplete.addListener('place_changed', () => {
            setSearchValue(autocomplete.getPlace())
        })
    }, [])

    function onGpsClickHandler(){        
        handleGps();
    }

    return (
        <div className="c-dealers-stores">
            <div className="c-dealers-stores__container">
                { device !== DEVICES.mobile && <h1 className="c-dealers__title">Distribuidores</h1> }
                { device === DEVICES.mobile && <SeeMap handleDefaultMapActive={handleDefaultMapActive}/> }
                <div className="c-dealers-search">
                    {
                        device !== DEVICES.mobile &&
                            <div className="c-dealers-search_iconLocation">
                               <GpsButton _section="stores_" _onClick={onGpsClickHandler}></GpsButton>
                            </div>
                    }
                    <div className="c-dealers-search__icon">
                        <Search/>
                    </div>
                    <input
                        ref={inputSearch}
                        type="text"
                        placeholder="Busca tu ciudad..."
                    />
                </div>
                <Dropdown items={states} default_item={(default_item) ? default_item.data.title.text: 'Selecciona un estado'} handleItemClick={handleState} type={2} disabled={loading} />
                <ul className="c-dealers-stores__list">
                    {
                        listStores.length > 0 && !loading ? listStores.map((store, i) => <li key={i} onClick={() => handleStore(store)} onMouseOver={() => handleOver(store)} onMouseLeave={() => handleOver(null)}>{store}</li>) : null
                    }
                </ul>
            </div>
        </div>
    )
}
export const WrappedStores = withScriptjs( Stores);
