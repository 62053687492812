// import { useStaticQuery, graphql } from "gatsby";
import { useState, useEffect } from 'react'
import Prismic from 'prismic-javascript'
import { states } from '../../static/assets/data/dealers/api-states';
import { isNullOrUndefined } from 'util';

export const usePrismLocations = () => {

    const apiEndpoint = "https://cinsa.cdn.prismic.io/api/v2";
    const apiToken = "MC5YZzRucGhVQUFDSUE2LWFM.77-977-9cCoaOFx1QO-_ve-_ve-_vVM1WO-_vSrvv73vv70K77-9I--_ve-_vXvvv714Rg9w77-9dw";
    // const apiEndpoint = "https://calorexw.cdn.prismic.io/api/v2";
    // const apiToken = "MC5YWVVtS2hFQUFDY0FkekFO.WG7vv70jegXvv71F77-977-9Ze-_ve-_ve-_ve-_ve-_vQnvv73vv707Ue-_ve-_ve-_vXtufe-_vX7vv73vv71x";
    const [locations, setLocations] = useState([]);
    const [currentState, setCurrentState] = useState(null)
    const [stores, setStores] = useState([])
    const [currentStore, setCurrentStore] = useState(null)
    const [currentSateId, setCurrentSateId] = useState(null);
    const [branchOffices, setBranchOffices] = useState([])
    const [currentBranchOffice, setCurrentBranchOffice] = useState(null)
    const [loading, setLoading] = useState(true)

    function handleState(state) {
        if(isNullOrUndefined(state)) return
        setCurrentState(state)
        setStores(locations.length > 0 ? locations : []);
        setBranchOffices([]);
    }

    function handleStore(storeSelected) {
        setCurrentStore(storeSelected)
    }

    function handleBranchoffice(itemSelected) {
        if(itemSelected != null){
            const coordinates = {
                lat: itemSelected.data.coordinates.latitude,
                lng: itemSelected.data.coordinates.longitude
            }
            setCurrentBranchOffice(itemSelected)
        }else{
            setCurrentBranchOffice(null)
        }
    }

    function handleBranchoffices(branchOffices) {
        setBranchOffices(branchOffices)
    }

    function handleSateId(id) {

        if(currentSateId === parseInt(id.state_id)) return

        setLoading(true)
        setCurrentSateId(parseInt(id.state_id))
    }

    const fetchData = async (page = 1) => {
        try {
              const api = await Prismic.getApi(apiEndpoint, { accessToken: apiToken });
              const response = await api.query(Prismic.Predicates.at('my.location.address_components.address_state_id', currentSateId), { pageSize: 25, page: page });
              return response;
          }
          catch (err) {
              console.log("Something went wrong: ", err);
          }
      }

    async function getData(page = 1){
        const response = await fetchData(page);
        if(response && response.next_page !== null){
            page += 1
            return response.results.concat(await getData(page))
        }else{
            return response.results
        }
    }

    useEffect(() => {
        if(currentSateId===null) return ;
        async function getLocations(){
            const allLocations = await  getData()
            
            setLocations(allLocations)
        }
        getLocations()
    }, [currentSateId,states])

    useEffect(() =>{
        let aux = false;
        states.map((state)=>{
            if(aux) return 
        
            if(currentSateId === parseInt(state.uid)){                
                handleState(state)
                aux = true
            }
        }) 
        setLoading(false)
        setStores(locations.length > 0 ? locations : []);
    },[locations])

    return [
        locations,
        setLocations,
        stores,
        currentState,
        setCurrentState,
        currentStore,
        branchOffices,
        currentBranchOffice,
        handleSateId,
        handleState,
        handleStore,
        handleBranchoffice,
        handleBranchoffices,
        loading,
        setLoading
    ]
}