import React, { useState, useRef, useEffect } from 'react';

import useOutsideClick from '../../hooks/useOutsideClick'
import IconArrow from '../svg/commons/arrow_head';
import "../../styles/_components/_comparator/_dropdown.scss";

const Dropdown = ({items, default_item = "name item", name, handleItemClick, _className="", disabled=false, label=false, questionName, isMaintenance=false}) => {
    const [isActive, setActive] = useState(false);
    const [dropdownLabel, setDropdownLabel] = useState(null);
    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef, setActive);

    //useEffect for add and remove class for z-index=1
    useEffect(()=>{
        if(isActive){
            wrapperRef.current.classList.add("o-dropdown__active");
        }else{
            wrapperRef.current.classList.remove("o-dropdown__active");
        }
    },[isActive])

    useEffect(()=>{
        if(disabled) {
            wrapperRef.current.classList.add('disabled')
            return
        }
        wrapperRef.current.classList.remove('disabled')

    },[disabled])

    function handleClick(item) {
        handleItemClick(item);
        if(isMaintenance){ //valida que los items venga del formulario de mantenimiento o contacto
            setDropdownLabel(item.value)
        }else{
            setDropdownLabel(item.data.title.text)
        }
        setActive(false);
    }

    function getItems() {
        return (
            <div className="o-dropdown__list">
                <div className="o-dropdown__list--wrapper" onClick={() => setActive(!isActive)}>
                    <ul className={ _className ? `${_className}_options_contact` : ""}>
                        {items.map( item => <li key={item.uid} onClick={() => handleClick(item)}>
                            { isMaintenance ? item.value : item.data.title.text }
                            </li>)}
                    </ul>
                </div>
            </div>
        )
    }

    return (
        <div className="o-dropdown" ref={wrapperRef}>
            { label && <label>{questionName}</label>}
            <div className={`o-dropdown__button ${_className}`} onClick={() => setActive(!isActive)} >
                <p>{dropdownLabel ? dropdownLabel : default_item}</p>
                <IconArrow fill='#6881DD' />
            </div>
            {
                isActive && getItems()
            }
        </div>
    );
};

export default Dropdown;