import React from "react"

const Search = props => {
  return (
    <svg viewBox="0 0 243 243.2" {...props}>
      <g data-name="Layer 2">
        <path
          d="M238 214v-1l-64-64a94 94 0 0017-54 95 95 0 10-96 96 97 97 0 0054-17l64 64a17 17 0 0025 0 18 18 0 000-24zM95 165a70 70 0 1170-70 70 70 0 01-70 70z"
          data-name="Layer 1"
        />
      </g>
    </svg>
  )
}

export default Search