import React from 'react';
import GpsIcon from '../../components/svg/dealers/gps_icon'
import styles from '../../styles/_components/_buttons/_button_gps.module.scss';

  
export const GpsButton= ({_section,_onClick=null,_className='', _classIcon=''})=>{     
    return (
        <div className={`o-gps-button ${styles.oGpsButton} ${_className}`} onClick={_onClick} id={`${_section}_gps_button`}>
            <GpsIcon className={_classIcon} section={_section} ></GpsIcon>
            
        </div>
        
    )
}

