import React from 'react'
import '../../styles/pages/dealers/_dealers_seemap.scss'
import SvgSeemapCurve from '../../components/svg/dealers/seemap_curve'

export const SeeMap  = ({handleDefaultMapActive}) => {
    return(
        <div className="c-dealer_seeMap">
            <div className="c-dealer_seeMap_point" onClick={()=>{handleDefaultMapActive(true)}}>
                <span></span>
                <div>ver mapa</div>
            </div>
            <img src="/assets/images/dealers/cinsa_mapa.jpg" alt=""/>
            <SvgSeemapCurve/>
        </div>
    )
}